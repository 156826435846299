import { createRouter, createWebHistory } from 'vue-router'
import { i18n, defaultLocale } from '../i18n'

//Views
import Page from '../views/Page'
import JournalOverview from '@/views/JournalOverview'
import JournalCategoryArchive from '@/views/JournalCategoryArchive'
import JournalEntry from '@/views/JournalEntry'
import EventEntry from '@/views/EventEntry'
import CoachingEntry from '@/views/CoachingEntry'
import FeaturedProjectEntry from '@/views/FeaturedProjectEntry'
import Search from '@/views/Search';
import NewsletterSubscriptionVerificationSuccess from '@/views/NewsletterSubscriptionVerificationSuccess'
import NewsletterUnsubscriptionSuccess from '@/views/NewsletterUnsubscriptionSuccess'

const routes = [
  {
    path: '/:locale(de|fr|en)?/',
    name: 'JournalOverview',
    components: {
        default: JournalOverview,
    },
  },
  { 
    path: '/:locale(de|fr|en)?/journal',
    redirect: { name: 'JournalOverview' }
  },
  {
    path: '/:locale(de|fr|en)?/stiftung/newsletter/anmeldung-bestaetigen', // TODO: check
    name: 'NewsletterSubscriptionVerificationSuccess',
    components: {
        default: NewsletterSubscriptionVerificationSuccess
    },
  },
  {
    path: '/:locale(de|fr|en)?/stiftung/newsletter/abmelden', //TODO: Check
    name: 'NewsletterUnsubscriptionSuccess',
    components: {
        default: NewsletterUnsubscriptionSuccess
    },
  },
  {
    path: '/:locale(de|fr|en)?/:path(suche|recherche|search)/:search?',
    name: 'Search',
    components: {
        default: Search
    }
  },
  {
    path: '/:locale(de|fr|en)?/journal/tag/:categorySlug',
    name: 'JournalCategoryArchive',
    props: true,
    components: {
        default: JournalCategoryArchive,
    },
  },
  {
    path: '/:locale(de|fr|en)?/journal/:slug',
    name: 'JournalEntry',
    components: {
        default: JournalEntry,
    },
  },
  {
    path: '/:locale(de|fr|en)?/:path(veranstaltungen|evenements|events)/:slug',
    name: 'EventEntry',
    components: {
        default: EventEntry,
    },
  },
  {
    path: '/:locale(de|fr|en)?/coaching/:slug',
    name: 'CoachingEntry',
    components: {
        default: CoachingEntry,
    },
  },
  {
    path: '/:locale(de|fr|en)?/:path(projekte|projets|projects)/:slug',
    name: 'FeaturedProjectEntry',
    components: {
        default: FeaturedProjectEntry,
    },
  },
  // {
  //   path: '/fr',
  //   alias: '/fr/:pathArr+',
  //   name: 'PageNotFound',
  //   components: {
  //       default: PageNotFound,
  //   },
  // },

  {
    path: '/:locale(de|fr|en)?/:pathArr+',
    name: 'Page',
    components: {
        default: Page
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
         to.meta.scrollPosition = savedPosition;
          //return savedPosition
      } else {
          to.meta.scrollPosition = { left: 0, top: 0 };
      }
  }
})

router.beforeEach((to, from , next) => {
  if (to.params.locale === from.params.locale) {
      next()
      return
  }

  const lang = to.params.locale || defaultLocale
  i18n.global.locale = lang

  next();
});

export default router

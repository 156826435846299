<template>
    <header class="site-header fixed-top" id="site-header">
        <div v-if="showMenu" class="main-navigation-overlay-backdrop" @click="toggleMenu"></div>
        <div class="main-navbar d-flex align-items-center container-fluid relative bg-white" id="site-header-navbar">
            <router-link to="/" class="logo-animation" :class="showMenu ? 'hidden-logo' : ''">
                <Vue3Lottie @mouseenter="hoverLogo" @mouseleave="resetLogo()" ref="logoAnimation" v-if="loaded" :animationData="animatedLogo" :autoPlay="false" height="100%" width="66px" :loop="0" @onComplete="logoAnimationEnded = true" />
            </router-link>
            <h1 class="navbar-brand flex-grow-1" :class="showMenu || hoveredLogo || showFontLogo ? 'alternate-brand' : ''">
                <router-link v-if="globalSiteData" :to="{ name: 'JournalOverview', params: { locale: this.$route.params.locale } }">{{ globalSiteData.siteName }}</router-link>
            </h1>
            <h2 @click="toggleMenu" role="button" v-if="pageRoot && pageRoot.title" class="site-header--current" :class="showMenu ? 'hide-title' : ''">{{ pageRoot.title }}</h2>
            <div class="site-header-toolbar d-flex align-items-center flex-grow-0 position-relative">
                <router-link :to="{ name: 'Search', params: { locale: this.$route.params.locale, path: $tc('routePaths.search') } }" class="search-btn" v-on:click="onSearchNavLinkClick">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20.117" height="20.117" viewBox="0 0 20.117 20.117">
                        <g transform="translate(-1283 -15)">
                            <g transform="translate(1283 15)" fill="none" stroke="currentColor" stroke-width="1.8">
                                <circle cx="8.086" cy="8.086" r="8.086" stroke="none" />
                                <circle cx="8.086" cy="8.086" r="7.186" fill="none" />
                            </g>
                            <line x2="5.881" y2="5.881" transform="translate(1296.6 28.6)" fill="none" stroke="currentColor" stroke-width="1.8" />
                        </g>
                    </svg>
                </router-link>
                <nav v-if="langNav && langNav.length" class="language-nav">
                    <button class="flex-grow-0 language-nav-btn language-nav-dropdown-toggle" @click="toggleLanguageDropdown" v-text="langNav[0].lang" />
                    <transition name="toggleLanguageDropdown">
                        <div v-if="langNav.length > 1 && showLanguageDropdown" class="language-nav-dropdown">
                            <router-link v-for="(langItem, index) in langNav.slice(1)" :key="index" :to="$url(langItem.path)" :title="langItem.lang" @click="closeLanguageDropdown" class="language-nav-btn">
                                <span class="hint-autotranslation" v-if="langItem.autoTranslated">{{ $tc('siteHeader.languageNav.autotranslated', {}, langItem.lang) }}</span> {{ langItem.lang }}
                            </router-link>
                        </div>
                    </transition>
                </nav>
                <button class="flex-grow-0 ml-auto menu-toggle menu-open" @click="toggleMenu" :class="{ 'menu-close': showMenu }">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21.264" height="15.541" style="margin-top: -4px">
                        <g fill="none" stroke="#000" stroke-width="2">
                            <path d="M21.264 1H0" class="top-line" />
                            <path d="M21.264 7.769H0" class="middle-line" />
                            <path d="M21.264 14.541H0" class="bottom-line" />
                        </g>
                    </svg>
                </button>
            </div>
        </div>
        <Collapse :when="showMenu" class="v-collapse">
            <div class="main-navigation-overlay container-fluid bg-white text-black">
                <MainNavigation @close-menu="toggleMenu" />
            </div>
        </Collapse>
    </header>
</template>

<script>
import { mapGetters } from 'vuex';
//import onFoundation from "./previewBlocks/animationContent/mixins/onFoundation.js";
import MainNavigation from '@/components/MainNavigation';
import { Collapse } from 'vue-collapsed';
import { Vue3Lottie } from 'vue3-lottie';
import lineLogo from '../assets/animations/line_logo.json';

export default {
    name: 'SiteHeader',
    components: {
        MainNavigation,
        Collapse,
        Vue3Lottie,
    },
    //mixins: [onFoundation],
    props: ['scroll'],
    data() {
        return {
            scrolledDown: false,
            mobileBreakpoint: 576,
            showMenu: false,
            showLanguageDropdown: false,
            supportPageOffset: window.pageXOffset !== undefined,
            isCSS1Compat: (document.compatMode || '') === 'CSS1Compat',
            coveringHeaderOffsetElement: null,
            s: '',
            sShow: false,
            animatedLogo: lineLogo,
            logoAnimationEnded: false,
            loaded: false,
            hoveredLogo: false,
            logoHoverActive: false,
            showFontLogo: false,
        };
    },
    computed: {
        ...mapGetters({
            view: 'view/get',
            activeView: 'navigation/getActive',
            pageRoot: 'navigation/getPageRoot',
            langNav: 'navigation/getLangNav',
        }),
        globalSiteData() {
            return this.$store.getters['general/get'];
        },
    },
    watch: {
        'view.showJournalStartAnimation': {
            handler(newValue) {
                if (newValue === false) {
                    setTimeout(() => this.$refs['logoAnimation'].playSegments([0, 40], true), 800);
                }
            },
        },
    },
    mounted() {
        window.addEventListener(
            'scroll',
            () => {
                this.closeLanguageDropdown();
            },
            true
        );
        this.loaded = true;
    },
    methods: {
        hoverLogo() {
            if (!this.logoHoverActive & !this.showMenu & !this.showFontLogo) {
                this.logoHoverActive = true;
                this.$refs['logoAnimation'].playSegments([40, 90], true);
                setTimeout(() => {}, 500);
                setTimeout(() => {
                    this.showFontLogo = true;
                    this.logoHoverActive = false;
                }, 1300);
            }
        },
        resetLogo() {
            if (!this.logoHoverActive) {
                this.showFontLogo = false;
                setTimeout(() => this.$refs['logoAnimation'].playSegments([0, 40], true), 300);
                this.logoHoverActive = false;
            }
        },
        swapLogo() {
            console.log('swap');
        },
        send(e) {
            e.preventDefault();
            this.$push(`suche/${this.s}`);
            this.toggleMenu();
            this.sShow = false;
            this.s = '';
        },
        onSearchNavLinkClick() {
            this.showMenu = false;
        },
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        toggleLanguageDropdown() {
            this.showLanguageDropdown = !this.showLanguageDropdown;
        },
        closeLanguageDropdown() {
            this.showLanguageDropdown = false;
        },
        getScrollTop() {
            return this.supportPageOffset ? window.pageYOffset : this.isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
        },
    },
};
</script>
<style>
.v-collapse {
    transition: height 300ms cubic-bezier(0.33, 1, 0.68, 1);
}
</style>
